import React, { Component } from 'react';

class Notfound extends Component {
    render () {
        return (
            <div className="container">
                <h1>Not found</h1>
            </div>

        )
    }
}

export default Notfound